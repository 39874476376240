import React from "react";
import "./Spinner.css";
function Loadingdot() {
  return (
    <div class="loader">
      <span class="loader__element"></span>
      <span class="loader__element"></span>
      <span class="loader__element"></span>
    </div>
  );
}

export default Loadingdot;
