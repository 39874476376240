import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../../Spinner/Spinner";
import "./ReserveList.css";
import SideBar from "../../Sidebar/Sidebar";
import TableRList from "./TableRList";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

function ReserveList() {
  const [loading, setloading] = useState(false);
  const [open, setopen] = useState(false);
  const [List, setList] = useState([]);
  const [viewList, setViewList] = useState([]);
  const fetchAll = async () => {
    try {
      setloading(true);
      let ReserveData = await axios.get(
        "https://api-digi.onrender.com/reservelist"
      );
      setList(ReserveData.data);

      setloading(false);
    } catch (err) {
      console.log("error Reserve List");
    }
  };

  let handleView = async (id) => {
    setopen(true);
    try {
      let viewdata = await axios.get(
        `https://api-digi.onrender.com/reserveview/${id}`,
        {
          headers: {
            Authorization: window.localStorage.getItem("myapptoken"),
          },
        }
      );
      setViewList(viewdata.data);
      setopen(false);
    } catch (err) {
      setopen(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <>
      <div className="container-fluid ">
        <div className="row  ">
          <div className="col-lg-2 p-0 ">
            <SideBar />
          </div>
          <div className="col-lg-10">
            <div>
              <h3 className="r-title ">Reserve List</h3>
            </div>
            <hr />
            <div className="nav-menu py-2 ps-4">
              <Link to="/frontdesk">FrontDesk</Link>
              <FaAngleRight className="sidebar-arrow" />
              <Link to="/frontdesk/reservelist">Reserve List</Link>
            </div>
            {loading ? (
              <Spinner />
            ) : (
              <TableRList
                List={List}
                viewList={viewList}
                handleView={handleView}
                open={open}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReserveList;
