import React from "react";

function RoomView({ view, viewloading }) {
  return (
    <div className="container-fluid ">
      <div className="row g-4 ">
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Room No</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.roomnumber}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Room Type</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.roomtype}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">AC</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.ac}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Bed</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.bed}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Price</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.price}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Room Status</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.roomstatus}</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center fw-bold">Booking Status</div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 border text-center">{view.bookingstatus}</div>
        </div>
      </div>
    </div>
  );
}

export default RoomView;
