import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./Search.css";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Sidebar from "../Sidebar/Sidebar";
import Spinner from "../Spinner/Spinner";
import { FaAngleRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#005fc1",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#ecf0f1",
    color: theme.palette.common.black,
    border: "#fff",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f6fa",
    color: theme.palette.common.black,
    border: theme.palette.common.black,
  },
}));
const available = [
  { id: "Room No", label: "Room No", minWidth: 60 },

  {
    id: "Room Type",
    label: "Room Type",
    minWidth: 140,
  },

  {
    id: "Room Status",
    label: "Room Status",
    minWidth: 140,
  },
  {
    id: "Ac",
    label: "Ac",
    minWidth: 120,
  },

  {
    id: "Bed",
    label: "Bed",
    minWidth: 110,
  },

  {
    id: "Action",
    label: "Action",
    minWidth: 150,
  },
];

function Search() {
  const [data, setdata] = useState([]);
  const [date, setdate] = useState(new Date());
  const [date3, setdate3] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [child, setChild] = useState(false);

  const [loading, setloading] = useState(false);
  const [opentable, setOpentable] = useState(false);

  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });
  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };
  const handlechild = () => {
    setChild(true);
  };
  const handlechild2 = () => {
    setChild(false);
  };
  let navigate = useNavigate();
  let handlesearch = async () => {
    setloading(true);
    setOpenOptions(false);
    try {
      let result = await axios.get(
        `https://api-digi.onrender.com/roomsavailable`
      );
      setdata(result.data);
      setloading(false);
      setOpentable(true);
    } catch (err) {
      console.log("error rooms view");
    }
  };

  let handleBook = async (id) => {
    navigate(`/frontdesk/booking/${id}`, { state: { date, date3, options } });
  };
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const num = 18;
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-10 ">
            <div className="row ">
              <div className="search-title ">
                <h3>Available Rooms</h3>
              </div>

              <hr />
              <div className="nav-menu py-2 ps-4">
                <Link to="/frontdesk">FrontDesk</Link>
                <FaAngleRight className="sidebar-arrow" />
                <Link to="/frontdesk/booking">Booking Room</Link>
              </div>
              <div className="input-container ">
                <div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={date}
                    minDate={new Date()}
                    onChange={(date) => setdate(date)}
                    required
                  />
                  {/* <input
                    type="date"
                    min={min}
                    className="  input-search"
                    placeholder="dd-mm-yyyy"
                    required
                    onChange={(event) => {
                      setdate(event.target.value);
                    }}
                  /> */}
                </div>
                <div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    minDate={date}
                    selected={date3}
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => setdate3(date)}
                    required
                  />
                </div>
                <div>
                  <button
                    className="room"
                    onClick={() => {
                      setOpenOptions(!openOptions);
                    }}
                  >
                    Adults <span>{options.adult}</span> & Childrens
                    <span>{options.children}</span> & Rooms
                    <span>{options.room}</span>
                  </button>

                  {openOptions && (
                    <div className="options">
                      <div className="optionItem">
                        <span className="optionText">Adult</span>
                        <div className="optionCounter">
                          <button
                            disabled={options.adult <= 1}
                            className="optionCounterButton"
                            onClick={() => handleOption("adult", "d")}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber">
                            {options.adult}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.adult >= 30}
                            onClick={() => handleOption("adult", "i")}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="optionItem">
                        <span className="optionText">Children</span>
                        <div className="optionCounter ">
                          <button
                            disabled={options.children <= 0}
                            className="optionCounterButton "
                            onClick={() => {
                              handleOption("children", "d");
                              handlechild2(options.children);
                            }}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber ">
                            {options.children}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.children >= 10}
                            onClick={() => {
                              handleOption("children", "i");
                              handlechild();
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {child && (
                        <div>
                          <Form.Select
                            id="age"
                            name="age"
                            className="book-list"
                          >
                            <option>-Age needed -</option>

                            <option value="0 "> 0 Years </option>
                            <option value="1 ">1 Years </option>
                            <option value="2 "> 2 Years </option>
                            <option value=" 3 "> 3 Years </option>
                            <option value=" 4 "> 4 Years </option>
                            <option value="5 ">5 Years </option>
                            <option value="6 "> 6 Years </option>
                            <option value="7 ">7 Years </option>
                            <option value="8 "> 8 Years </option>
                            <option value="9 ">9 Years </option>
                            <option value="10 "> 10 Years </option>
                            <option value="11 ">11 Years </option>
                            <option value="12 "> 12 Years </option>
                            <option value=" 13 "> 13 Years </option>
                            <option value=" 14 "> 14 Years </option>
                            <option value="15 ">15 Years </option>
                            <option value="16 "> 16 Years </option>
                            <option value="17 ">17 Years </option>
                          </Form.Select>
                        </div>
                      )}
                      <div className="optionItem">
                        <span className="optionText">Room</span>
                        <div className="optionCounter">
                          <button
                            disabled={options.room <= 1}
                            className="optionCounterButton"
                            onClick={() => handleOption("room", "d")}
                          >
                            -
                          </button>
                          <span className="optionCounterNumber">
                            {options.room}
                          </span>
                          <button
                            className="optionCounterButton"
                            disabled={options.room >= 30}
                            onClick={() => handleOption("room", "i")}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <input
                    type="submit"
                    className="btn-se"
                    value="Search"
                    onClick={handlesearch}
                  />
                </div>
              </div>
              <div>
                <Paper
                  className="table-head  mt-2 "
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    marginLeft: "5px",
                  }}
                >
                  <TableContainer sx={{ maxHeight: 407 }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      sx={{
                        "& .MuiTableRow-root:hover": {
                          border: "black",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          {available.map((column) => (
                            <StyledTableCell
                              style={{
                                minWidth: column.minWidth,
                                padding: column.padding,
                                border: "1px solid #ece9e9",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {loading ? (
                        <Spinner />
                      ) : (
                        <TableBody>
                          {data
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )

                            .map((row) => {
                              return (
                                <StyledTableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  style={{
                                    border: "1px solid #ece9e9",
                                    textAlign: "center",
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.roomnumber}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.roomtype}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.roomstatus === "Available" ? (
                                      <p className="text-success ">
                                        {row.roomstatus}
                                      </p>
                                    ) : (
                                      <p className="text-danger">
                                        {row.roomstatus}
                                      </p>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.ac}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.bed}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      border: "1px solid #ece9e9",
                                      textAlign: "center",
                                    }}
                                  >
                                    <button
                                      className="btn btn-add "
                                      onClick={() => {
                                        handleBook(row._id);
                                      }}
                                    >
                                      Book Now
                                    </button>
                                  </TableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="p-0"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
