import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Sidebar from "../../Sidebar/Sidebar";
import Spinner from "../../Spinner/Spinner";
import "./Reservebook.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactDatePicker from "react-datepicker";

function Booking() {
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  const [loading, setloading] = useState(false);
  const [sdate, setsdate] = useState(location.state.date);
  const [sdate2, setsdate2] = useState(location.state.date3);

  let fetchAll = async () => {
    setloading(true);
    try {
      let roomdata = await axios.get(
        `https://api-digi.onrender.com/roomsview/${params.id}`,
        {
          headers: {
            Authorization: window.localStorage.getItem("myapptoken"),
          },
        }
      );

      let bookingdata = await axios.get(
        "https://api-digi.onrender.com/reservelist"
      );
      formik.setValues({
        roomno: roomdata.data.roomnumber,
        type: roomdata.data.roomtype,
        price: roomdata.data.price,
        ac: roomdata.data.ac,
        bed: roomdata.data.bed,
        bookingstatus: "UnAvailable",
        bookingno: bookingdata.data.length + 1,
        checkin: location.state.date,
        checkout: location.state.date3,
        noofguest:
          location.state.options.adult + location.state.options.children,
      });
      setloading(false);
    } catch (error) {
      setloading(false);
      alert("Something went wrong booking", error);
    }
  };

  let formik = useFormik({
    initialValues: {
      roomno: "",
      type: "",
      price: "",
      ac: "",
      bed: "",
      bookingno: "",
      mode: "",
      meal: "",
      advance: "",
      noofguest: "",
      checkin: "",
      checkout: "",
      firstname: "",
      lastname: "",
      email: "",
      gender: "",
      contact: "",
      idtype: "",
      idno: "",
      idfile: "",
      address: "",
      note: "",
      bookingstatus: "",
      status: "",
    },

    onSubmit: async (values) => {
      try {
        setloading(true);

        await axios
          .post(`https://api-digi.onrender.com/reserve`, values)
          .then(async () => {
            navigate("/frontdesk/reserevelist");
            swal(" Reservation Successfully", {
              icon: "success",
              timer: 3000,
            });
            fetchAll();
          })
          .then(async () => {
            const status = { bookingstatus: "UnAvailable" };
            await axios.put(
              `https://api-digi.onrender.com/roomsedit/${params.id}`,
              status,
              {
                headers: {
                  Authorization: window.localStorage.getItem("myapptoken"),
                },
              }
            );
            navigate("/frontdesk/reservelist");

            fetchAll();
          });

        setloading(false);
      } catch (error) {
        alert(" Booking Error" + error.message);
      }
    },
  });

  const date = new Date();
  let min =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 p-0 ">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-md-8 col-sm-6  ">
            {loading ? (
              <Spinner />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="col-lg-12 book-con">
                  <h3 className="book-ti"> Room Information </h3>
                  <hr />
                </div>
                <div className="row g-2 ps-3  ">
                  <div className="col-lg-12 d-none ">
                    <label className="form-label fs-6"> Booking </label>
                    <input
                      type="text"
                      className="form-control text-center bg-white text-secondary"
                      id="bookingstatus"
                      name="bookingstatus"
                      onChange={formik.handleChange}
                      value={formik.values.bookingstatus}
                      readOnly
                    />
                  </div>
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel label="Booking No">
                        <Form.Control
                          type="number"
                          id="bookingno"
                          name="bookingno"
                          onChange={formik.handleChange}
                          value={formik.values.bookingno}
                          readOnly
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label=" Room No">
                        <Form.Control
                          name="roomno"
                          id="roomno"
                          onChange={formik.handleChange}
                          value={formik.values.roomno}
                          readOnly
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label="Room Type">
                        <Form.Control
                          id="type"
                          name="type"
                          onChange={formik.handleChange}
                          value={formik.values.type}
                          readOnly
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label=" Price">
                        <Form.Control
                          name="price"
                          id="price"
                          onChange={formik.handleChange}
                          value={formik.values.price}
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel controlId="floatingSelect" label="Status">
                        <Form.Select
                          id="status"
                          name="status"
                          onChange={formik.handleChange}
                          value={formik.values.status}
                          className="book-list"
                        >
                          <option>-Select -</option>
                          <option value="Reserved"> Reserved </option>
                          <option value="Checked In">Checked In </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>

                    <Col md>
                      <FloatingLabel controlId="floatingSelect" label="   Bed">
                        <Form.Select
                          id="bed"
                          name="bed"
                          onChange={formik.handleChange}
                          value={formik.values.bed}
                          className="book-list"
                        >
                          <option>-Select -</option>
                          <option value="1">1</option>
                          <option value="2">2 </option>
                          <option value="3">3</option>
                          <option value="4">4 </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="  Arrival Mode"
                      >
                        <Form.Select
                          aria-label="Floating label select example"
                          id="mode"
                          name="mode"
                          onChange={formik.handleChange}
                          value={formik.values.mode}
                          required
                          className="book-list"
                        >
                          <option>-Select -</option>
                          <option value="Walk In"> Walk In </option>
                          <option value="Company"> Company </option>
                          <option value="travel Agent"> travel Agent </option>

                          <option value="Other OTA"> Other OTA </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="    Meal Type"
                      >
                        <Form.Select
                          id="meal"
                          name="meal"
                          onChange={formik.handleChange}
                          value={formik.values.meal}
                          required
                          className="book-list"
                        >
                          <option>-Select -</option>
                          <option value="AP"> AP </option>
                          <option value="MAP"> MAP </option>
                          <option value="CP"> CP </option>
                          <option value="EP"> EP </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel label="   Advance" className="mb-3">
                        <Form.Control
                          type="number"
                          id="advance"
                          name="advance"
                          min="100"
                          max="10000"
                          onChange={formik.handleChange}
                          value={formik.values.advance}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label=" No Of Guests"
                      >
                        <Form.Select
                          id="noofguest"
                          name="noofguest"
                          onChange={formik.handleChange}
                          value={formik.values.noofguest}
                          required
                          className="book-list"
                        >
                          <option> -Select - </option>
                          <option value="1"> 1 </option>
                          <option value="2"> 2 </option>
                          <option value="3"> 3 </option>
                          <option value="4"> 4 </option>
                          <option value="5"> 5 </option>
                          <option value="6"> 6 </option>
                          <option value="7"> 7 </option>
                          <option value="8"> 8 </option>
                          <option value="9"> 9 </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <ReactDatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={sdate}
                        minDate={new Date()}
                        name="checkin"
                        className="book-date"
                        id="checkin"
                        onChange={(date) => setsdate(date)}
                        value={formik.values.checkin}
                      />
                      {/* <FloatingLabel label="  Check In">
                        <Form.Control
                          type="date"
                          id="checkin"
                          min={min}
                          name="checkin"
                          onChange={formik.handleChange}
                          value={formik.values.checkin}
                          required
                          className="book-list"
                        />
                      </FloatingLabel> */}
                    </Col>
                    <Col md>
                      <ReactDatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={sdate2}
                        minDate={sdate}
                        placeholderText="DD/MM/YYYY"
                        id="checkout"
                        name="checkout"
                        className="book-date"
                        onChange={(date) => setsdate2(date)}
                        value={formik.values.checkout}
                      />
                      {/* <ReactDatePicker
                        dateFormat="dd/MM/yyyy"
                        id="checkout"
                        name="checkout"
                        onChange={formik.handleChange}
                        value={formik.values.checkout}
                        className="book-list"
                        required
                      /> */}
                      {/* <FloatingLabel label="  Check Out">
                        <Form.Control
                          type="date"
                          id="checkout"
                          name="checkout"
                          min={formik.values.checkin}
                          onChange={formik.handleChange}
                          value={formik.values.checkout}
                          required
                          className="book-list"
                        />
                      </FloatingLabel> */}
                    </Col>
                  </Row>
                  <h3 className="mt-lg-1"> Customer Information </h3> <hr />
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel label="First  Name">
                        <Form.Control
                          type="text"
                          id="firstname"
                          name="firstname"
                          placeholder="First Name"
                          onChange={formik.handleChange}
                          value={formik.values.firstname}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label=" Last Name">
                        <Form.Control
                          type="text"
                          id="lastname"
                          name="lastname"
                          placeholder="Last Name"
                          onChange={formik.handleChange}
                          value={formik.values.lastname}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label="   Email">
                        <Form.Control
                          type="email"
                          placeholder="email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label="   Contact">
                        <Form.Control
                          id="contact"
                          name="contact"
                          placeholder="contact"
                          type="text"
                          minLength={10}
                          maxLength={10}
                          onChange={formik.handleChange}
                          value={formik.values.contact}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel controlId="floatingSelect" label=" Gender">
                        <Form.Select
                          id="gender"
                          name="gender"
                          placeholder="gender"
                          onChange={formik.handleChange}
                          value={formik.values.gender}
                          required
                          className="book-list"
                        >
                          <option>-Select -</option>
                          <option value=" male"> Male</option>
                          <option value="female ">Female </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel
                        controlId="floatingSelect"
                        label="  ID Type"
                      >
                        <Form.Select
                          id="idtype"
                          name="idtype"
                          placeholder="idtype"
                          onChange={formik.handleChange}
                          value={formik.values.idtype}
                          required
                          className="book-list"
                        >
                          <option> -Select - </option>
                          <option value="aadharcard"> Aadhar Card </option>
                          <option value="pancard"> Pan Card </option>
                          <option value="driving license">
                            Driving license
                          </option>
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label="  ID No">
                        <Form.Control
                          type="text"
                          minLength={10}
                          maxLength={10}
                          placeholder="idno"
                          id="idno"
                          name="idno"
                          onChange={formik.handleChange}
                          value={formik.values.idno}
                          required
                          className="book-list"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <div>
                        <input
                          className="form-control file-input book-list"
                          type="file"
                          id="idfile"
                          placeholder="Chosse"
                          name="idfile"
                          onChange={formik.handleChange}
                          accept="image/x-png,image/gif,image/jpeg"
                          value={formik.values.idfile}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2 ">
                    <Col md>
                      <FloatingLabel label="  Address">
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px" }}
                          id="address"
                          name="address"
                          onChange={formik.handleChange}
                          value={formik.values.address}
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel label=" Note">
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "100px" }}
                          id="note"
                          name="note"
                          onChange={formik.handleChange}
                          value={formik.values.note}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <div className="col-lg-3 my-3 mx-auto">
                    <input
                      type="submit"
                      disabled={loading}
                      className="btn  booking-btn"
                      value="Book Hotel "
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Booking;
