import React from "react";
import "./Spinner.css";
function Spinner() {
  return (
    <div className="spinner">
      <span></span>
    </div>
  );
}

export default Spinner;
